<template>
  <div>
    <div class="filter mb-5" style="display: flex; align-items: center;">
      <div style="display: flex;">
        <div style="width: 40%" class="mr-2">
          <vs-select
            style="position: relative;"
            placeholder="Select Clinic"
            autocomplete
            v-model="clinic"
            label="Clinic"
            class="w-full m-2"
          >
            <vs-select-item
              :key="index"
              :value="item.clinicId"
              :text="item.clinicName"
              v-for="(item, index) in clinics"
            />
          </vs-select>
        </div>

        <div style="width: 20%" class="mr-2">
          <vs-select
            label="Year"
            class="w-full m-2"
            placeholder="Select Year"
            autocomplete
            v-model="selectedYear"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in yearDropdown"
            />
          </vs-select>
        </div>

        <div style="width: 20%" class="mr-2">
          <vs-select
            label="Month"
            class="w-full m-2"
            :placeholder="monthDropdown[currentMonth - 1]"
            autocomplete
            v-model="selectedMonth"
          >
            <vs-select-item
              v-for="(item, index) in monthDropdown"
              :key="`${item}-${index}`"
              :value="item"
              :text="item"
            />
          </vs-select>
        </div>
      </div>

      <div style="color: #63D4C2; font-weight: 600;">
        <p>Need assistance with an invoice? Call 1300 375 646</p>
      </div>
    </div>

    <Invoices :clinicId="clinic" :year="selectedYear" :month="selectedMonth" />
  </div>
</template>

<script>
import Invoices from "@/views/components/invoice/List.vue";
import { mapActions } from "vuex";

export default {
  name: "FranchiseClinicInvoices",
  components: {
    Invoices
  },
  data() {
    return {
      clinics: [{ clinicId: "all", clinicName: "All" }],
      monthDropdown: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      currentMonth: new Date().getMonth() + 1,
      selectedMonth: "",
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      yearDropdown: [],
      clinic: "all"
    };
  },
  methods: {
    ...mapActions("franchise", ["fetchClinics"]),
    getYearDropdown() {
      this.yearDropdown = [];
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    }
  },
  created() {
    this.fetchClinics({ type: "franchise" }).then(res => {
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        res.data.data.map(clinic => {
          this.clinics.push({
            clinicId: clinic._id,
            clinicName: clinic.clinicName
          });
        });
      }
    });
    this.getYearDropdown();
    this.selectedMonth = this.monthDropdown[new Date().getMonth()];
  }
};
</script>

<style scoped>
div.vs-select--options {
  position: fixed !important;
}
</style>
